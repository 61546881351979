@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
    font-family: 'Body';
    src: local('Brandon Grotesque'), url("./fonts/brandon-grotesque.woff") format('woff');
}

@font-face {
    font-family: 'Secondary';
    src: local('Brandon Grotesque Bold Italic'), url("./fonts/brandon-grotesque-bold-italic.woff") format('woff');
}

@font-face {
    font-family: 'Script';
    src: local('Goliette'), url("./fonts/Goliette.woff") format('woff');
}

@font-face {
    font-family: 'Script2';
    src: local('Retro Signature'), url("./fonts/RetroSignature.woff") format('woff');
}

body {
    margin: 0;
    font-family: 'Wittgenstein', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(30, 30, 30);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scripted-font {
    font-family: 'Script', sans-serif !important;
}

.scripted-font-alternate {
    font-family: 'Script2' !important;
}

.header-font {
    font-family: 'Body', sans-serif !important;
}

.body-font {
    font-family: 'Bitter', sans-serif !important;
}

.secondary-font-shadow {
    font-family: 'Body', sans-serif !important;
    text-shadow: 0 0 10px black;
}

.secondary-font-colour-3 {
    font-family: 'Body', sans-serif !important;
    color: rgb(187, 57, 60)
}
